export default {
  'list-title': 'Company List',
  'create-title': 'Create Company',
  'edit-title': 'Company edit',
  'list-admin-title': 'List of Company Administrators',
  'create-admin-title': 'Create Company Administrator',
  'edit-admin-title': 'Company Administrator edit',
  'create-button': 'Create',
  'edit-button': 'Edit',
  'updata-button': 'Edit',
  'delete-button': 'Delete',
  'companies': {
    'company-name': 'Company Name',
    'address': 'Address',
    'city': 'City',
    'state': 'State/Province/Region',
    'country': 'Country/Region',
    'zip': 'Zip/Postal Code',
    'date': 'Registration Date',
  },
  'error-message': {
    'enter-company-name': 'Please enter your company name',
    'company-name-in-use': 'This company name is already in use',
    'enter-first-name': 'Please enter your first name',
    'incorrect-first-name': 'Incorrect first name entered',
    'enter-last-name': 'Please enter your last name',
    'incorrect-last-name': 'Incorrect last name entered',
    'enter-email': 'Please enter a valid email address',
    'email-in-use': 'This email address is already in use',
    'enter-address': 'Please enter your address',
    'enter-city': 'Please enter city',
    'enter-state': 'Please enter your state',
    'enter-country': 'Please enter your country/region',
    'enter-zip': 'Please enter your zip code',
    'select-mfa': 'Please select multi-factor authentication',
    'creation-update-failed': 'Creation/update failed',
  },
  'user': {
    'name': 'Name',
    'first-name': 'First name',
    'last-name': 'Last name',
    'middle-name': 'Middle name',
    'email': 'Mail address',
    'multi-factor-auth': 'Multi factor auth',
    'multi-factor-auth-true': 'validity',
    'multi-factor-auth-false': 'disabled',
    'created': 'Creation date',
    'activate-status': 'Activate Status',
  },
}
