import repositoryFactory from "@/repositories/repository-factory"

export const getLoginURL = () => {
  return
}

export const isAuthenticated = () => {
  return new Promise((resolve) => {
    resolve()
  })
}

export const loginUser = () => {
  repositoryFactory.auth.getLoginURL()
}

export const changePassword = (redirectUri) => {
  repositoryFactory.auth.changePassword(redirectUri)
}

export const exchangeCode = (code, state, callback) => {
  return repositoryFactory.auth.exchangeCode(code, state, callback)
}

/** 未使用？ */
export const executeRedirect = (code, state) => {
  repositoryFactory.auth.executeRedirect(code, state)
}

export const getUserMeInfo = async () => {
  return await repositoryFactory.auth.getUserMeInfo()
}

export const postLogout = (callback) => {
  return repositoryFactory.auth.postLogout(callback)
}
