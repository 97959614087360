import repository from '@/repositories/repository'
import UserInfo from '@/models/user-info'
import Cookies from 'js-cookie'

const tenantId = Cookies.get('tenant_id')
const companyId = Cookies.get('company_id')
const basePath = `/api/v1/tenants`

const getUserListToModelUserInfo = (userList) => {
  let userInfoList = []

  if ( Array.isArray(userList) ) {
    userList.forEach( function(user) {
      const userInfo = new UserInfo({
        userId: user.user_id,
        userName: user.user_name,
        email: undefined,
        authority: undefined,
        role: user.role,
        createdAt: user.created_at,
      })
      userInfoList.push(userInfo)
    })
  }

  return userInfoList
}

export default {
  getPilotUserList(callback) {
    repository.get(`${basePath}/${tenantId}/companies/${companyId}/pilots`)
      .then(function (response) {
        callback(getUserListToModelUserInfo(response.data.body))
      })
      .catch(function (error) {
        console.error(error)
      })
      .finally(function () {})
  },
}
