export default class MissionInfo {
  constructor(props) {
    this.missionId = props.missionId
    this.missionName = props.missionName
    this.pilotId = props.pilotId
    this.missionDate = props.missionDate
    this.shootingTarget = props.shootingTarget
    this.targetTypeId = props.targetTypeId
    this.place = props.place
    this.cameraId = props.cameraId
    this.cameraSettings = props.cameraSettings
    this.status = props.status
    this.totalNumber = props.totalNumber
    this.uploadedNumber = props.uploadedNumber
  }
}
