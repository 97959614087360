export default {
  'site-title': 'Drone Data Cloud',
  'headline': 'メニュー',
  'home': 'ホーム',
  'login': 'ログイン',
  'mission-list': 'ミッション一覧',
  'password-update': 'アカウント設定',
  'device-list': 'デバイス一覧',
  'drone-user': 'ドローンパイロット一覧',
  'company-user': 'カンパニーユーザー一覧',
  'tenant-user': 'テナントユーザー一覧',
  'company-list': 'カンパニー一覧',
  'open-source-license': 'オープンソースライセンス',
  'logout': 'ログアウト',
}
