import Cookies from 'js-cookie'

/**
 * ログイン済みか？
 * Cookieのアクセストークンに値が設定されている場合はログイン済みとみなす。
 * @return {boolean} true:ログイン済み、false:未ログイン
 */
export const isLogin = () => {
  const TOKEN = Cookies.get('access_token')
  if (TOKEN) {
    return true
  } else {
    return false
  }
}

export const isDrone = () => {
  const role = Cookies.get('role')
  if (role == '') {
    return true
  } else {
    return false
  }
}

export const isCompany = () => {
  const role = Cookies.get('role')
  if (role == '1') {
    return true
  } else {
    return false
  }
}

export const isTenant = () => {
  const role = Cookies.get('role')
  if (role == '0') {
    return true
  } else {
    return false
  }
}
