export default {
  'list-title': 'カンパニー一覧',
  'create-title': 'カンパニー作成',
  'edit-title': 'カンパニー編集',
  'list-admin-title': 'カンパニー管理者一覧',
  'create-admin-title': 'カンパニー管理者作成',
  'edit-admin-title': 'カンパニー管理者編集/削除',
  'create-button': '作成',
  'edit-button': '編集/削除',
  'updata-button': '編集',
  'delete-button': '削除',
  'companies': {
    'company-name': '会社名',
    'address': '町名、丁目、番地、建物名、部屋番号',
    'city': '市区町村',
    'state': '都道府県',
    'country': '国',
    'zip': '郵便番号',
    'date': 'アカウント登録日',
  },
  'error-message': {
    'enter-company-name': 'カンパニー名を入力してください',
    'company-name-in-use': 'この会社名はすでに使用されています',
    'enter-first-name': '名を入力してください',
    'incorrect-first-name': '名前が正しく入力されていません',
    'enter-last-name': '姓を入力してください',
    'incorrect-last-name': '姓が正しく入力されていません',
    'enter-email': '有効なメールアドレスを入力してください',
    'email-in-use': 'このメールアドレスは既に使用されています',
    'enter-address': '町名、丁目、番地、建物名、部屋番号を入力してください',
    'enter-city': '市区町村を入力してください',
    'enter-state': '都道府県を入力してください',
    'enter-country': '国を入力してください',
    'enter-zip': '郵便番号を入力してください',
    'select-mfa': '多要素認証を選択してください',
    'creation-update-failed': '作成・更新に失敗しました',
  },
  'user': {
    'name': '名前',
    'first-name': '名',
    'last-name': '姓',
    'middle-name': 'ミドルネーム',
    'email': 'メール',
    'multi-factor-auth': '認証',
    'multi-factor-auth-true': '有効',
    'multi-factor-auth-false': '無効',
    'created': '作成日',
    'activate-status': 'アクティベートステータス',
  },
}
