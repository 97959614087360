export default class UserInfo {
  constructor(props) {
    this.userId = props.userId
    this.userName = props.userName
    this.email = props.email
    this.authority = props.authority
    this.role = props.role
    this.createdAt = props.createdAt
  }
}
