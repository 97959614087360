import repository from '@/repositories/repository'
import Cookies from 'js-cookie'
import ImgInfo from '@/models/img-info'

const basePath = () => {
  return `/api/v1/tenants/${Cookies.get("tenant_id")}/companies/${Cookies.get("company_id")}/missions`
}

const toModelImgInfo = ({
  image_id,
  image,
}) => {
  return new ImgInfo({
    imgId: image_id,
    imgSrc: image
  })
}

const toModelImgInfos = (imgfos) => {
  let modelImgInfos = []

  if (imgfos != null) {
    imgfos.forEach( function(ImgInfo) {
      modelImgInfos.push(toModelImgInfo(ImgInfo))
    })
  }

  return modelImgInfos
}

export default {
  getImgInfos(missionId, callback) {
    repository.get(`${basePath()}/${missionId}/thumbnail_images`)
      .then(function (response) {
        callback(toModelImgInfos(response.data.body))
      })
      .catch(function (error) {
        console.error(error)
      })
      .finally(function () {})
  },
  getImgInfo(missionId, imgId, callback) {
    const resultImgId = encodeURIComponent(imgId)
    repository.get(`${basePath()}/${missionId}/images/${resultImgId}/original_image`)
      .then(function (response) {
        callback(response.data.body)
      })
      .catch(function (error) {
        console.error(error)
      })
      .finally(function () {})
  },
}
