import Cookies from 'js-cookie'
import repository from '@/repositories/repository'
import AuthInfo from '@/models/auth-info'

export const BASE_URL = '/api/v1'

/**
 * Cookieに認証情報をセット
 * @param {import('axios').AxiosResponse} response 取得APIのレスポンス
 */
export const setAuthenticateInfo = (response) => {
  Cookies.set('access_token', response.data.body.access_token)
  Cookies.set('refresh_token', response.data.body.refresh_token)
  Cookies.set('expires_in', response.data.body.expires_in)
}

/**
 * Cookieから認証情報を削除
 */
export const removeAuthenticateInfo = () => {
  Cookies.remove('access_token')
  Cookies.remove('refresh_token')
  Cookies.remove('expires_in')
}

/**
 * CookieにUserMe情報をセットし、取得情報をモデルにセットして返却
 * @param {import('axios').AxiosResponse} response 取得APIのレスポンス
 * @return {AuthInfo} 認証情報モデル
 */
export const setUserMe = (response) => {
  Cookies.set('tenant_id', response.data.body.tenant_id)
  Cookies.set('company_id', response.data.body.company_id)
  Cookies.set('user_id', response.data.body.user_id)
  Cookies.set('role', response.data.body.role)
  return new AuthInfo({
    userId: response.data.body.user_id,
    userName: response.data.body.user_name,
    tenantId: response.data.body.tenant_id,
    companyId: response.data.body.company_id,
    role: response.data.body.role,
    s3Bucket: response.data.body.s3_bucket,
    websocketEndpoints: response.data.body.websocket_endpoints,
  })
}

/**
 * CookieからUserMe情報を削除
 */
export const removeUserMe = () => {
  Cookies.remove('tenant_id')
  Cookies.remove('company_id')
  Cookies.remove('user_id')
  Cookies.remove('role')
}

export const isAccessToken = () => {
  if ( Cookies.get('access_token') ) {
    return true
  } else {
    return false
  }
}

export const getAuthenticateHeader = () => {
  return {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + Cookies.get('access_token'),
  }
}

export const postTokenRefresh = async () => {
  try {
    const tokenRefreshRequest = {
      refresh_token: Cookies.get('refresh_token'),
    }
    const response = await repository.post(`${BASE_URL}/token:refresh`, tokenRefreshRequest)
    setAuthenticateInfo(response)
    return response
  } catch(error) {
    // トークン更新に失敗したら、cookieの認証情報を削除しておく
    removeAuthenticateInfo()
    removeUserMe()
    return Promise.reject(error)
  }
}
