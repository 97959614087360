export default {
  'list-title-drone': 'Drone Pilot User List',
  'list-title-company': 'Company User List',
  'list-title-tenant': 'Tenant User List',
  'detail-title-drone': 'Drone Pilot User Details',
  'detail-title-company': 'Company User Details',
  'detail-title-tenant': 'Tenant User Details',
  'create-title-drone': 'Drone Pilot User Creation',
  'create-title-company': 'Company User Creation',
  'create-title-tenant': 'Tenant User Creation',
  'edit-title-drone': 'Drone Pilot User Edit/Delete',
  'edit-title-company': 'Company User Edit/Delete',
  'edit-title-tenant': 'Tenant User Edit/Delete',
  'create-title': 'Create user',
  'edit-title': 'User edit/delete',
  'detail-title': 'User details',
  'create-button': 'Create',
  'edit-button': 'Edit/Delete',
  'updata-button': 'Edit',
  'delete-button': 'Delete',
  'detail': {
    'user-name': 'User name',
    'mail': 'Mail',
    'authority': 'Multi factor auth',
    'status': 'Status',
    'flight-status': 'Flight status',
    'total-flight-time': 'Total flight-time',
    'mission-list': 'List of missions taken',
    'date': 'Creation date',
    'status-invalid': 'Invalid',
    'status-validity': 'Validity',
    'flight-status-inactive': 'Inactive',
    'flight-status-active': 'Active',
    'multi-factor-auth-true': 'validity',
    'multi-factor-auth-false': 'disabled',
    'first-name': 'First Name',
    'last-name': 'Last Name',
    'middle-name': 'Middle Name',
    'activate-status': 'Activate Status',
  },
  'error-message': {
    'enter-first-name': 'Please enter your first name',
    'incorrect-first-name': 'Incorrect first name entered',
    'enter-last-name': 'Please enter your last name',
    'incorrect-last-name': 'Incorrect last name entered',
    'enter-email': 'Please enter a valid email address',
    'email-in-use': 'This email address is already in use',
    'select-mfa': 'Please select multi-factor authentication',
    'creation-update-failed': 'Creation/update failed',
  },
}
