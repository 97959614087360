import repository from '@/repositories/repository'
import Cookies from 'js-cookie'
import CompanyAdminInfo from '@/models/company-admin-info'

const tenantId = () => {
  return Cookies.get('tenant_id')
}
const pilotId = () => {
  return Cookies.get('user_id')
}
const basePath = () => {
  return `/api/v1/company`
}

const toModelCompanyAdminInfo = ({
  id,
  status,
  created,
  first_name,
  last_name,
  middle_name,
  email,
  multi_factor_auth,
  role,
}) => {
  return new CompanyAdminInfo({
    id: id,
    activateStatus: status,
    created: created,
    firstName: first_name,
    lastName: last_name,
    middleName: middle_name,
    email: email,
    multiFactorAuth: multi_factor_auth,
    role: role,
  })
}

const toModelCompanyAdminInfos = (companyAdminInfos) => {
  let modelCompanyAdminInfos = []

  companyAdminInfos.forEach( function(companyAdminInfo) {
    modelCompanyAdminInfos.push(toModelCompanyAdminInfo(companyAdminInfo))
  })

  return modelCompanyAdminInfos
}

export default {
  getCompanyAdminInfos(companyID, callback) {
    repository
      .get(`${basePath()}/admins?tenant_id=${tenantId()}&company_id=${companyID}&user_id=${pilotId()}`)
      .then(function (response) {
        callback(toModelCompanyAdminInfos(response.data.body))
      })
      .catch(function (error) {
        if (error.response.status === 403 || error.response.status === 400) {
          window.location.href = '/'
        } else {
          console.error(error)
        }
      })
      .finally(function () {})
  },
  createCompanyAdminInfo(companyID, companyAdminInfo) {
    if (companyAdminInfo instanceof CompanyAdminInfo) {
      const createCompanyAdminInfoRequest = {
        first_name: companyAdminInfo.firstName,
        last_name: companyAdminInfo.lastName,
        middle_name: companyAdminInfo.middleName,
        email: companyAdminInfo.email,
        multi_factor_auth: companyAdminInfo.multiFactorAuth,
      }
      return repository.post(`${basePath()}/admin?tenant_id=${tenantId()}&company_id=${companyID}&user_id=${pilotId()}`, createCompanyAdminInfoRequest)
    }
  },
}
