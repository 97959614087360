import { BASE_URL, setAuthenticateInfo, setUserMe } from "./interceptors/auth";
import repository from "@/repositories/repository";
import { v4 as uuidv4 } from "uuid";
import pkceChallenge, { generateChallenge } from "pkce-challenge";
import Cookies from "js-cookie";

const REDIRECT_URI = process.env.VUE_APP_REDIRECT_URI;

const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=; path=/; max-age=0;";
  }
}

export default {
  getLoginURL() {
    // 処理前に全Cookie削除
    deleteAllCookies()

    const { code_verifier } = pkceChallenge();
    const state = uuidv4();
    Cookies.set("code_verifier", code_verifier);
    Cookies.set("state", state);

    const params = {
      'redirect_uri': REDIRECT_URI,
      'lang': 'en', // TODO: set ui lang
      'country': 'US', // TODO: set ui country
      'state': state ?? '',
      'code_challenge': generateChallenge(code_verifier ?? ''),
      'code_challenge_method': 'S256',
    }

    repository
      .get(`${BASE_URL}/auth`, {params})
      .then(function (response) {
        console.log(response);
        window.location.href = response.data.body.location;
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(function () {});
  },
  changePassword() {
    const params = {
      'lang': 'en', // TODO: set ui lang
    }

    repository
      .get(`${BASE_URL}/accountSetting`, {params})
      .then(function (response) {
        // 200OKで返ることはなく、正常の場合は302リダイレクトで返ってくる想定のため、ここに入ることはない
        // 現在は、200OKの中に302リダイレクトのステータスコードが含まれている形で返ってくる
        window.location.href = response.data.body.location;
      })
      .catch(function (error) {
        if (error.status === 302) {
          window.location.href = error.response.data.body.location;
        } else {
          console.error(error);
        }
      })
      .finally(function () {});
  },
  exchangeCode(code, state, callback) {
    if (state != Cookies.get("state")) {
      console.error("state is invalid");
      return;
    }
    const code_verifier = Cookies.get("code_verifier");
    repository
      .post(`${BASE_URL}/token`, {
        redirect_uri: REDIRECT_URI,
        code_verifier: code_verifier,
        code: code,
      })
      .then(function (response) {
        Cookies.remove("state");
        Cookies.remove("code_verifier");
        setAuthenticateInfo(response);
        if (callback) callback();
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(function () {});
  },
  /** 未使用？ */
  executeRedirect(code, state) {
    const requestURL = new URL(
      `${process.env.VUE_APP_API_BASE_URL}/api/v1/redirect`
    );
    requestURL.searchParams.set("code", code ?? "");
    requestURL.searchParams.set("state", state ?? "");
    repository
      .get(requestURL.href)
      .then(function (response) {
        console.log(response);
        window.location.href = response.data.body.location;
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(function () {});
  },
  async getUserMeInfo() {
    try {
      const response = await repository.get(`${BASE_URL}/user/me`);
      return setUserMe(response);
    } catch(error) {
      return Promise.reject(error);
    }
  },
  postLogout(callback) {
    const logoutRequest = {
      access_token: Cookies.get("access_token"),
    };
    repository
      .post(`${BASE_URL}/token:revoke`, logoutRequest)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(function () {
        deleteAllCookies();
        callback();
      });
  },
};
