export default {
  'site-title': 'Drone Data Cloud',
  'headline': 'Menu',
  'home': 'Home',
  'login': 'Login',
  'mission-list': 'Mission list',
  'password-update': 'Account settings',
  'device-list': 'Device list',
  'drone-user': 'Drone pilot list',
  'company-user': 'Company User list',
  'tenant-user': 'Tenant User list',
  'company-list': 'Company List',
  'open-source-license': 'open-source license',
  'logout': 'Logout',
}
