import { isDrone, isCompany, isTenant } from '@/services/account'

/**
 * テナントユーザーの場合は対象を表示し、それ以外の場合はトップページに遷移させる。
 * @param {import('vue-router').RouteLocationNormalized} to 次にナビゲーションされる対象の ルートオブジェクト
 * @param {import('vue-router').RouteLocationNormalized} from ナビゲーションされる前の現在のルート
 * @param {import('vue-router').NavigationGuardNext} next ナビゲーションガードの最後にnext()関数を呼び出す
 */
export const isTenantRoleView = (to, from, next) => {
  if (isTenant()) {
    next()
  } else {
    next({ name: 'HomeView' })
  }
}

/**
 * ドローンユーザーの以外場合は対象を表示し、それ以外の場合はトップページに遷移させる。
 * @param {import('vue-router').RouteLocationNormalized} to 次にナビゲーションされる対象の ルートオブジェクト
 * @param {import('vue-router').RouteLocationNormalized} from ナビゲーションされる前の現在のルート
 * @param {import('vue-router').NavigationGuardNext} next ナビゲーションガードの最後にnext()関数を呼び出す
 */
export const isNotDroneRoleView = (to, from, next) => {
  if (!isDrone()) {
    next()
  } else {
    next({ name: 'HomeView' })
  }
}

/**
 * ドローンユーザーまたはカンパニーユーザーの場合は対象を表示し、それ以外の場合はトップページに遷移させる。
 * @param {import('vue-router').RouteLocationNormalized} to 次にナビゲーションされる対象の ルートオブジェクト
 * @param {import('vue-router').RouteLocationNormalized} from ナビゲーションされる前の現在のルート
 * @param {import('vue-router').NavigationGuardNext} next ナビゲーションガードの最後にnext()関数を呼び出す
 */
export const isDroneOrCompanyRoleView = (to, from, next) => {
  if (isDrone() || isCompany()) {
    next()
  } else {
    next({ name: 'HomeView' })
  }
}

/**
 * カンパニーユーザーの場合は対象を表示し、それ以外の場合はトップページに遷移させる。
 * @param {import('vue-router').RouteLocationNormalized} to 次にナビゲーションされる対象の ルートオブジェクト
 * @param {import('vue-router').RouteLocationNormalized} from ナビゲーションされる前の現在のルート
 * @param {import('vue-router').NavigationGuardNext} next ナビゲーションガードの最後にnext()関数を呼び出す
 */
export const isCompanyRoleView = (to, from, next) => {
  if (isCompany()) {
    next()
  } else {
    next({ name: 'HomeView' })
  }
}
