import repository from '@/repositories/repository'
import Cookies from 'js-cookie'
import MissionInfo from '@/models/mission-info'

const tenantId = () => {
  return Cookies.get('tenant_id')
}
const companyId = () => {
  return Cookies.get('company_id')
}
const pilotId = () => {
  return Cookies.get('user_id')
}
const basePath = () => {
  return `/api/v1/tenants/${tenantId()}/companies/${companyId()}`
}

const toModelMissionInfo = ({
  mission_id,
  name,
  pilot_id,
  date,
  target,
  object_id,
  place,
  camera_id,
  camera_settings,
  status,
  total_number,
  uploaded_number,
}) => {
  return new MissionInfo({
    missionId: mission_id,
    missionName: name,
    pilotId: pilot_id,
    missionDate: date,
    shootingTarget: target,
    targetTypeId: object_id,
    place: place,
    cameraId: camera_id,
    cameraSettings: camera_settings,
    status: status,
    totalNumber: total_number,
    uploadedNumber: uploaded_number,
  })
}

const toModelMissionInfos = (missionInfos) => {
  let modelMissionInfos = []

  missionInfos.forEach( function(missionInfo) {
    modelMissionInfos.push(toModelMissionInfo(missionInfo))
  })

  return modelMissionInfos
}

export default {
  getMissionInfos(callback) {
    repository.get(`${basePath()}/missions`)
      .then(function (response) {
        callback(toModelMissionInfos(response.data.body))
      })
      .catch(function (error) {
        console.error(error)
      })
      .finally(function () {})
  },
  getMissionInfo(missionId, callback) {
    repository.get(`${basePath()}/pilots/${pilotId()}/missions/${missionId}`)
      .then(function (response) {
        callback(toModelMissionInfo(response.data.body))
      })
      .catch(function (error) {
        if (error.response.status === 403 || error.response.status === 400) {
          window.location.href = '/'
        } else {
          console.error(error)
        }
      })
      .finally(function () {})
  },
  createMissionInfo(missionInfo) {
    if (missionInfo instanceof MissionInfo) {
      const createMissionInfoRequest = {
        name: missionInfo.missionName,
        pilot_id: missionInfo.pilotId,
        date: missionInfo.missionDate,
        target: missionInfo.shootingTarget,
        object_id: missionInfo.targetTypeId,
        camera_id: '',
        camera_settings: '',
        place: missionInfo.place,
      }
      return repository.post(`${basePath()}/pilots/${missionInfo.pilotId}/mission`, createMissionInfoRequest)
    }
  },
  updateMissionInfo(missionInfo) {
    if (missionInfo instanceof MissionInfo) {
      const updateMissionInfoRequest = {
        name: missionInfo.missionName,
        pilot_id: missionInfo.pilotId,
        date: missionInfo.missionDate,
        target: missionInfo.shootingTarget,
        object_id: missionInfo.targetTypeId,
        camera_id: '',
        camera_settings: '',
        place: missionInfo.place,
      }
      return repository.post(`${basePath()}/pilots/${missionInfo.pilotId}/missions/${missionInfo.missionId}`, updateMissionInfoRequest)
    }
  },
}
