export default {
  'list-title-drone': 'ドローンパイロットユーザー一覧',
  'list-title-company': 'カンパニーユーザー一覧',
  'list-title-tenant': 'テナントユーザー一覧',
  'detail-title-drone': 'ドローンパイロットユーザー詳細',
  'detail-title-company': 'カンパニーユーザー詳細',
  'detail-title-tenant': 'テナントユーザー詳細',
  'create-title-drone': 'ドローンパイロットユーザー作成',
  'create-title-company': 'カンパニーユーザー作成',
  'create-title-tenant': 'テナントユーザー作成',
  'edit-title-drone': 'ドローンパイロットユーザー編集/削除',
  'edit-title-company': 'カンパニーユーザー編集/削除',
  'edit-title-tenant': 'テナントユーザー編集/削除',
  'create-title': 'ユーザー作成',
  'edit-title': 'ユーザー編集/削除',
  'detail-title': 'ユーザー詳細',
  'create-button': '作成',
  'edit-button': '編集/削除',
  'updata-button': '編集',
  'delete-button': '削除',
  'detail': {
    'user-name': 'ユーザー名',
    'mail': 'メールアドレス',
    'authority': '権限',
    'status': 'ステータス',
    'flight-status': 'フライトステータス',
    'total-flight-time': 'トータルの飛行時間',
    'mission-list': '撮影したミッション一覧',
    'date': 'アカウント登録日',
    'status-invalid': '無効',
    'status-validity': '有効',
    'flight-status-inactive': '非アクティブ',
    'flight-status-active': 'アクティブ',
    'multi-factor-auth-true': '有効',
    'multi-factor-auth-false': '無効',
    'first-name': '名',
    'last-name': '姓',
    'middle-name': 'ミドルネーム',
    'activate-status': 'アクティベートステータス',
  },
  'error-message': {
    'enter-first-name': '名を入力してください',
    'incorrect-first-name': '名前が正しく入力されていません',
    'enter-last-name': '姓を入力してください',
    'incorrect-last-name': '姓が正しく入力されていません',
    'enter-email': '有効なメールアドレスを入力してください',
    'email-in-use': 'このメールアドレスは既に使用されています',
    'select-mfa': '多要素認証を選択してください',
    'creation-update-failed': '作成・更新に失敗しました',
  },
}
