import axios from 'axios'
import { getAuthenticateHeader, isAccessToken, postTokenRefresh, removeAuthenticateInfo, removeUserMe } from './interceptors/auth'

const baseURL = process.env.VUE_APP_API_BASE_URL

const api = axios.create({
  baseURL,
})

let isRefresh = false

const sleep = waitTime => new Promise( resolve => setTimeout(resolve, waitTime) )

const retryApi = async (config) => {
  // 1秒後にリフレッシュ完了する想定でリトライし、それでもエラーの場合はcookieの認証情報を削除しておく
  await sleep(1000)
  try {
    return await api.request(config)
  } catch(error) {
    removeAuthenticateInfo()
    removeUserMe()
    return Promise.reject(error)
  }
}

api.interceptors.response.use( response => {
  // APIレスポンス共通処理
  return response
}, error => {
  // 認証エラーの場合は、トークンをリフレッシュし、それ以外はエラーとしてそのまま返す
  if (error.config && error.response && error.response.status === 401 && !error.config._retry && !isRefresh) {
    error.config._retry = true
    isRefresh = true
    console.log(error.config)
    return postTokenRefresh()
      .then( () => {
        isRefresh = false
        return api.request(error.config)
      })
      .catch( (err) => {
        return Promise.reject(err)
      })
  } else if (error.config._retry) {
    // リフレッシュ後のリトライでエラーになった場合、cookieの認証情報を削除しておく
    removeAuthenticateInfo()
    removeUserMe()
    return Promise.reject(error)
  } else if (isRefresh) {
    // リフレッシュ中の場合は、1秒後にリフレッシュ完了する想定でリトライし、それでもエラーの場合はcookieの認証情報を削除しておく
    return retryApi(error.config)
  } else {
    return Promise.reject(error)
  }
})

// APIリクエスト共通処理
api.interceptors.request.use( config => {
  // リクエストが送信される前の処理

  // ログイン後に呼ぶAPI（Cookieにアクセストークンがある場合に呼ぶAPI）はヘッダーにアクセストークンをつける
  if (isAccessToken()) {
    config.headers = getAuthenticateHeader()
  }

  return config
}, error => {
  // リクエスト エラーの処理
  return Promise.reject(error)
})

export default api
